import Icon from '@mdi/react';
import Spinner from './Spinner';
import axios, { handleError } from '../util/axios';
import Google from './icons/Google';
import { mdiFacebook } from '@mdi/js';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';
import { AuthContext } from './context/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Divider,
    FacebookButton,
    ForgotPassword,
    FormContainer,
    GoogleButton,
    Input,
    StyledAlert,
    StyledButton,
    StyledForm,
    StyledShimmer
} from '../styles/LoginForm.styles';
import { getUrlParams } from '../util/url';
import ExternalLink from './ExternalLink';
import AuthButtons from './AuthButtons';

const LoginForm: React.FC = () => {
    const { login } = useContext(AuthContext);
    const { register, handleSubmit, errors } = useForm();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [redirect, setRedirect] = useState('/');

    useEffect(() => {
        if (getUrlParams(document.location.search).error_code === "500") {
            setError('An error occurred during authentication. Please try ' +
                'again, or contact support if the problem persists.');
        }
    }, [])

    useEffect(() => {
        const redirectPath = getUrlParams(document.location.search).redirect;
        if (!redirectPath) return;

        setRedirect(redirectPath);
    }, [redirect]);

    const onSubmit = async data => {
        setIsLoading(true);

        try {
            const response = await axios.post('/login', data);

            login(response.data.token);

            if (redirect) {
                navigate(redirect);
                return;
            }

            navigate('/');
        } catch (err) {
            setIsLoading(false);

            handleError(err, () => {
                if ([400,401].includes(err.response.status)) {
                    setError('The email or password that you have entered is incorrect.');
                    return;
                }

                console.log(err.response.status);
                setError('An error occurred. Please try again, or contact support if the issue persists.');
            })
        }
    }

    return (
        <Container>
            <FormContainer>
                { error && <StyledAlert message={error} type="error"/>}

                <AuthButtons cta={'Sign in'} redirectPath={redirect}/>

                <Divider>or</Divider>

                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <ErrorMessage errors={errors} field="email" />
                    <Input name="email" placeholder="email" type="email" ref={register({ required: true })}/>

                    <ErrorMessage errors={errors} field="password" />
                    <Input name="password" placeholder="password" type="password" ref={register({ required: true })}/>

                    <ForgotPassword><Link to={'/forgot-password'}>Forgot password?</Link></ForgotPassword>

                    <StyledButton type="submit" variant="contained">
                        { isLoading ? <Spinner color={'#fff'} /> : 'Sign In' }
                    </StyledButton>
                </StyledForm>
            </FormContainer>
        </Container>
    );
};

export default LoginForm;
