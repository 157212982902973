import React from 'react';
import Layout from "../components/layouts/Layout";
import LoginForm from "../components/LoginForm";
import SEO from '../components/seo/SEO';

const login: React.FC = () => {
    return (
        <Layout customSEO>
            <SEO title={'Login'} />
            <LoginForm />
        </Layout>
    );
};

export default login;
